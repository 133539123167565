const markerPath = (
  <>
    <defs>
      <clipPath id="117a5f8b6a">
        <path
          d="M 0.339844 2 L 246.59375 2 L 246.59375 373 L 0.339844 373 Z M 0.339844 2 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="d4fe529b6f">
        <path
          d="M 72 76 L 175 76 L 175 162 L 72 162 Z M 72 76 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="17429be98f">
        <path
          d="M 192.292969 109.613281 L 156.257812 181.210938 L 56.167969 130.835938 L 92.199219 59.238281 Z M 192.292969 109.613281 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="590e9e49cb">
        <path
          d="M 78.761719 96.046875 C 81.574219 80.601562 99.601562 76.179688 113.292969 76.960938 C 126.945312 77.742188 140.164062 83.480469 150.976562 91.6875 C 163.890625 101.492188 184.058594 125.238281 169.613281 141.078125 C 160.339844 140.625 151.878906 138.417969 144.355469 134.480469 C 136.6875 130.464844 132.25 125.828125 127.554688 120.921875 C 123.535156 116.722656 119.382812 112.378906 112.957031 108.085938 C 103.367188 101.675781 91.898438 97.644531 78.761719 96.046875 Z M 117.246094 158.710938 C 132.867188 163.257812 164.113281 165.300781 168.058594 144.03125 C 158.894531 143.40625 150.484375 141.113281 142.949219 137.167969 C 134.839844 132.925781 130.234375 128.113281 125.359375 123.019531 C 121.457031 118.9375 117.417969 114.71875 111.269531 110.609375 C 101.75 104.242188 90.257812 100.320312 77.015625 98.902344 C 67.316406 110.34375 74.378906 126.929688 82.964844 137.21875 C 91.726562 147.71875 104.214844 154.914062 117.246094 158.710938 Z M 117.246094 158.710938 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#117a5f8b6a)">
      <path
        d="M 123.492188 2.53125 C 55.445312 2.53125 0.261719 57.710938 0.261719 125.785156 C 0.261719 193.832031 123.492188 372.214844 123.492188 372.214844 C 123.492188 372.214844 246.71875 193.832031 246.71875 125.785156 C 246.71875 57.710938 191.539062 2.53125 123.492188 2.53125 Z M 123.492188 184.128906 C 87.667969 184.128906 58.632812 155.121094 58.632812 119.269531 C 58.632812 83.472656 87.667969 54.4375 123.492188 54.4375 C 159.3125 54.4375 188.320312 83.472656 188.320312 119.269531 C 188.320312 155.121094 159.3125 184.128906 123.492188 184.128906 Z M 123.492188 184.128906 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#d4fe529b6f)">
      <g clipPath="url(#17429be98f)">
        <g clipPath="url(#590e9e49cb)">
          <path
            d="M 186.953125 108.308594 L 152.027344 177.703125 L 60.035156 131.402344 L 94.957031 62.007812 Z M 186.953125 108.308594 "
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </>
);

const bookmarkPath = (
  <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z" />
);

const coffeeBeanPath = (
  <path d="M 40.121094 1.0625 C 54.996094 -3.316406 66.90625 10.628906 72.296875 23.054688 C 77.667969 35.449219 78.472656 49.660156 76.035156 62.847656 C 73.121094 78.59375 61.121094 106.933594 40.730469 101.222656 C 37.015625 92.839844 35.203125 84.394531 35.335938 76.007812 C 35.476562 67.457031 37.59375 61.484375 39.839844 55.160156 C 41.761719 49.75 43.746094 44.15625 44.683594 36.578125 C 46.082031 25.273438 44.546875 13.363281 40.121094 1.0625 Z M 1.921875 62.847656 C 4.84375 78.648438 16.917969 107.125 37.4375 101.160156 C 33.914062 92.796875 32.207031 84.359375 32.339844 75.960938 C 32.484375 66.917969 34.6875 60.71875 37.015625 54.15625 C 38.882812 48.898438 40.8125 43.460938 41.710938 36.210938 C 43.097656 24.984375 41.453125 13.101562 36.828125 0.789062 C 22.421875 -2.6875 10.925781 10.910156 5.660156 23.054688 C 0.285156 35.449219 -0.519531 49.660156 1.921875 62.847656 Z M 1.921875 62.847656 " />
);

export { markerPath, bookmarkPath, coffeeBeanPath };
